body {
  margin: 0;
  font-family: 'Helvetica For Target', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: Helvetica For Target;
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/HelveticaForTarget_n1.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: italic;
  font-weight: 100;
  src: url('./assets/fonts/HelveticaForTarget_i1.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/HelveticaForTarget_n2.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/HelveticaForTarget_i2.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: normal;
  font-weight: normal;
  /* 400 */
  src: url('./assets/fonts/HelveticaForTarget_n4.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: italic;
  font-weight: normal;
  /* 400 */
  src: url('./assets/fonts/HelveticaForTarget_i4.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/HelveticaForTarget_n5.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/HelveticaForTarget_i5.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: normal;
  font-weight: bold;
  /* 700 */
  src: url('./assets/fonts/HelveticaForTarget_n7.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: italic;
  font-weight: bold;
  /* 700 */
  src: url('./assets/fonts/HelveticaForTarget_i7.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/HelveticaForTarget_n8.woff2') format('woff2');
}

@font-face {
  font-family: Helvetica For Target;
  font-style: italic;
  font-weight: 900;
  src: url('./assets/fonts/HelveticaForTarget_i8.woff2') format('woff2');
}
