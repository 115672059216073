.inputStyles {
  border: 2px solid #e6e6e6;
  border-radius: 50px;
  font-size: 14px;
  width: 330px;
  padding: 14px 20px;
  font-family: Helvetica For Target;
  margin-bottom: 4px;
  &:focus {
    outline: 2px dotted #e6e6e6;
    outline-offset: 2;
    border: none;
    margin-bottom: 6px;
    .mobilePrefix {
      top: 4px;
      color: #cc0000 !important;
    }
  }
  &:focus-visible {
    outline: 2px solid #7f7f7f;
    outline-offset: 2;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
  &::placeholder {
    color: #777777;
    font-family: Helvetica For Target;
  }
}
.customButton {
  font-size: 14px;
  padding: 12px 26px;
  margin-bottom: 30px;
  border-radius: 50px;
  background: #cc0000;
  color: #fff;
  min-width: 125px;
  font-family: Helvetica For Target;
  font-weight: 500;
  border: none;
  cursor: pointer;
  &:hover {
    /* background-color: #d61b32; */
    box-shadow: none;
  }
  &:focus {
    background: #d61b32;
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
}

.customButtonDisabled {
  font-size: 14px;
  padding: 12px 26px;
  margin-bottom: 30px;
  border-radius: 50px;
  background: grey;
  color: #fff;
  min-width: 125px;
  font-family: Helvetica For Target;
  font-weight: 500;
  border: none;
  cursor: default;
  &:hover {
    /* background-color: #d61b32; */
    box-shadow: none;
  }
  &:focus {
    background: #d61b32;
    outline: 2px dotted #fff;
    outline-offset: 2px;
  }
}

/* .customButton:hover {
  background-color: #d61b32;
  box-shadow: none;
} */
