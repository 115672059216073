.time-container {
  /* background-image: linear-gradient(to right, #cc0000 2%, #8d255d 70%); */
  background-color: #cc0000 !important;
  display: flex;
  color: #fff;
}
.time-container td:first-child {
  border-right: 10px solid #171c27;
  background-color: #2c303a !important;
}
.agenda-container {
  /* background: linear-gradient(90deg, rgb(8, 5, 8) 26%, rgb(46, 24, 38) 82%, rgb(77, 42, 58) 100%); */
  background-color: #171c27;
}

.agenda-table-container {
  width: 100%;
  margin-top: 20px;
  color: #fff;
  padding: 0 6% 2% 6%;
  /* background: linear-gradient(90deg, rgb(8, 5, 8) 26%, rgb(46, 24, 38) 82%, rgb(77, 42, 58) 100%); */
  background-color: #171c27;
}
.agenda-table-container td {
  color: #fff;
  border-bottom: none;
  padding-left: 40px;
  /* border-right: 0.5px solid; */
  /* display: table-cell; */
}
.agenda-table {
  /* border-spacing: 5px !important; */
  border-collapse: separate !important;
}
