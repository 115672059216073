.banner-title {
  font-family: 'Helvetica For Target';
  /* font-size: 48px; */
  font-weight: 700;
  line-height: 1;
  text-align: left;
  vertical-align: top;
  color: rgba(255, 255, 255, 255);
  margin: 0;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: none;
  margin-bottom: 20px;
}
.banner-subtitle {
  font-family: 'Helvetica For Target';
  /* font-size: 24px; */
  font-weight: 300;
  line-height: 1;
  text-align: left;
  vertical-align: top;
  color: rgba(255, 255, 255, 255);
  margin: 0;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: none;
}
.banner-copy {
  font-size: 16px;
  color: #fff;
}
