.elevate-hero-banner p a:focus,
.elevate-hero-banner p a {
  color: #fff !important;
}
.elevate-hero-banner p a:focus {
  outline: 1px dotted #fff !important;
}
.visibility-hidden {
  display: none;
}
.location_info a {
  color: #fff;
}
.location_info {
  margin-top: -5px;
}
.faq_links a {
  color: #000;
}
.tandc ul li {
  font-family: 'Helvetica For Target';
  font-size: 20px;
}
.tandc ol li {
  font-family: 'Helvetica For Target';
  font-size: 20px;
}
/* body * {
  outline: 1px solid red !important;
} */
